const cardData = [
    {
        id: 1,
        img: "../card_image/S black.png",
        price: "₹ Revealing Soon...",
        name: "S-Module",
        totalSales: "247",
        btnTitle: "Add to cart",
        rating: 5,
        url: "/products/s1-module",
    },

    {
        id: 2,
        img: "../card_image/0P5A4415.png",
        price: "₹ Revealing Soon...",
        name: "P-Module",
        totalSales: "247",
        title: "Add to cart",
        btnTitle: "Add to cart",
        rating: "5",
        url: "/products/p-module",
    },
    {
        id: 3,
        img: "../card_image/Module E LED black.png",
        price: "₹₹ Revealing Soon...",
        name: "E-Module",
        totalSales: "247",
        btnTitle: "Add to cart",
        rating: "5",
        url: "/products/e-module",
    },
    {
        id: 4,
        img: "../card_image/CModuleImage.png",
        price: "₹ Revealing Soon...",
        name: "C-Module",
        totalSales: "247",
        btnTitle: "Add to cart",
        title: "Add to cart",
        rating: "5",
        url: "/products/c-module",
    },
    {
        id: 5,
        img: "../card_image/0P5A4400.png",
        price: "₹ Revealing Soon...",
        name: "T-Module",
        totalSales: "247",
        btnTitle: "Add to cart",
        rating: "5",
        url: "/products/t-module",
    },
    {
        id: 6,
        img: "../card_image/R_black.png",
        price: "₹ Revealing Soon...",
        name: "R-Module",
        totalSales: "247",
        btnTitle: "Add to cart",
        rating: "5",
        url: "/products/r-module",
    },
    {
        id: 7,
        img: "../card_image/0P5A4392.png",
        price: "₹ Revealing Soon...",
        name: "U-Module",
        totalSales: "247",
        btnTitle: "Add to cart",
        rating: "5",
        url: "/products/u-module",
    },
    {
        id: 8,
        img: "../card_image/0P5A4387.png",
        price: "₹ Revealing Soon...",
        name: "M1-Module",
        totalSales: "247",
        btnTitle: "Add to cart",
        rating: "5",
        url: "/products/m1-module",
    },
    {
        id: 9,
        img: "../card_image/Module Square 8M.png",
        price: "₹ Revealing Soon...",
        name: "M3-Module",
        totalSales: "247",
        btnTitle: "Add to cart",
        rating: "5",
        url: "/products/m3-module",
    },
];

export default cardData;
